import {
  MDBAnimation,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React from "react";
import { Helmet } from "react-helmet";
import { Parallax } from "react-parallax";
import { withRouter } from "react-router-dom";
import { scrollTo } from "../../App";
import DataRequestor, {
  ENTITY_BLOG_POSTS,
  GET,
  STRAPI_URL,
} from "../../data/DataRequestor";
import { Logger } from "../../data/Logger";
import ScrollToTop from "../Nav/ScrollToTop";
import LogoSlider from "./LogoSlider";
import "./style.css";

class Home extends React.Component {
  logger = new Logger();

  constructor(props) {
    super(props);
    this.state = {
      requestor: new DataRequestor(),
      limit: 3,
      isMore: true,
      data: [],
      collapsed: false,
    };
  }

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getData();
  }

  getData() {
    let url =
      STRAPI_URL +
      ENTITY_BLOG_POSTS +
      `?_start=${this.state.data.length}&_limit=${
        this.state.limit
      }&publishDate_lte=${this.state.requestor.getDateStr()}&_sort=publishDate:DESC`;
    this.state.requestor.fetch(this, url, GET);
  }

  fetchSuccess(data) {
    if (data) {
      this.setState({
        data: data,
        isMore: data.length < this.state.limit,
      });
      this.logger.log(`blogs fetch success`);
      this.logger.log(data);
    }
  }

  fetchFailed(error) {
    this.logger.log(error);
  }

  scrollToGetStarted() {
    var testDiv = document.getElementById("get-started");
    window.scroll({
      top: testDiv.offsetTop - 25,
      behavior: "smooth",
    });
  }

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.handleTogglerClick}
      />
    );

    return (
      <div id="apppage" style={{ overflow: "hidden" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="canonical" href="https://www.taurusnz.co.nz" />
        </Helmet>
        <ScrollToTop />
        <Parallax
          bgImage={require("../../assets/img/home-banner.png")}
          bgImageAlt="Taurus Group"
          strength={100}
          className="page-header header-filter clear-filter white-text"
          // style={{
          //   filter: "grayscale(1)",
          // }}
          // style={{ borderBottom: '6px solid grey' }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            }}
          />
          <MDBContainer className="text-left">
            <div style={{ zIndex: "2", position: "relative" }}>
              <MDBCol md="8" className="pt-lg-5">
                <MDBAnimation reveal type="flipInX" className="slow">
                  <h1 className="display-h2 pt-5 font-nanum-b">
                    Creating sustainable{" "}
                    <span className="sky-text">financial</span> solutions.
                  </h1>
                </MDBAnimation>
                {/* <hr className="pl-4 mb-2 mt-4 d-inline-block mx-auto hr-line" /> */}
                <h6 className="h5-responsive mt-lg-5 mt-3 mb-lg-5 mb-4 font-nanum-eb">
                  Focus on growing your business, trust the accounting to us.
                </h6>
                {/* <MDBBtn outline color="white" style={{ minWidth: '220px' }} onClick={() => scrollTo('financial-solutions-company')}>About us</MDBBtn> */}
                {/* <MDBBtn color="white" outline style={{ minWidth: '220px' }} onClick={() => this.props.history.push('financial-solutions-company')}>About Us</MDBBtn> */}
                <MDBBtn
                  color="primary"
                  style={{ minWidth: "220px" }}
                  onClick={() => this.props.history.push("contact")}
                >
                  Get in touch
                </MDBBtn>
              </MDBCol>
            </div>
          </MDBContainer>
          <div className="down-arrow" onClick={() => scrollTo("get-started")}>
            <MDBAnimation type="tada" infinite className="slow">
              <MDBIcon className="grey-text" size="1x" icon="chevron-down" />
            </MDBAnimation>
          </div>
          <div style={{ minHeight: "85vh" }} />
        </Parallax>

        <div>
          <LogoSlider />
        </div>

        {/* <div style={{ backgroundColor: 'whitesmoke' }} className='padding-lg' id='get-started'>
          <MDBContainer className='pt-5 pb-5'>
            <MDBRow className='mt-5 mb-5'>
              <MDBCol md="12" className='mx-auto pb-5'>
                <h6 className='h5-responsive mb-2 font-space-xs tblue-text font-nanum-eb text-title'>
                    Experienced Consultant
                </h6>
              </MDBCol>
              <MDBCol md='5' className='mx-auto text-center pb-4'>
                <MDBAnimation reveal type='fadeIn' className='slow'>
                  <div className='display-h2 font-weight-md text-lg-right text-left mb-3 tblue-text font-nanum-eb'>
                    Over 40 years as a Consultant.
                  </div>
                </MDBAnimation>
              </MDBCol>
              <MDBCol md='7' className='mx-auto mt-lg-5 pl-lg-5 grey-text text-right'>
                <div className='mt-1'>
                  <h6 className='h5-responsive text-left mb-2 font-space-xs'>
                    With over 40 years experience as a Consultant, Wayne Bailey is immensely proud of the role he plays helping clients to achieve realistic solutions to their financial situations.
                  </h6>
                  <h6 className='h5-responsive text-left font-space-xs'>
                    Now working as a specialist consultant, Wayne is an expert in corporate restructuring, financial proposals and succession planning.
                  </h6>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div> */}

        {/* <MDBAnimation reveal type='fadeIn' className='slow'>
          <div className='home-banner page-header header-filter clear-filter blue-filter secondary-text'>
            <MDBMask overlay='black-strong' className='w-100 pt-5 pb-5'>
              <MDBCol md='10' lg='9' xl='8' className='mx-auto mt-5 mb-5'>
                <MDBContainer className='pt-5 pb-5 text-left'>
                  <h3 className='text-center white-text font-nanum-eb'>
                    Taurus is now maintaining an <span className='font-weight-md sky-text'>exclusive</span> portfolio of clients.
                  </h3>
                </MDBContainer>
              </MDBCol>
            </MDBMask>
          </div>
        </MDBAnimation> */}

        {/* <div className='pt-5 pb-5' id='services'>
          <div className='padding-lg'>
            <MDBAnimation reveal type='fadeIn' className='slow'>
              <MDBContainer className='pl-lg-5 pr-lg-5 pl-4 pr-4 mx-auto' style={{ maxWidth: '1380px' }}>
                <h2 className='text-center mt-5 mb-5 display-h2 font-weight-md tblue-text font-nanum-eb'>Financial services</h2>
                <MDBRow className='pb-5'>
                  <MDBCol
                    sm='12'
                    md='6'
                    lg='6'
                    className='mx-auto no-padding-margin-sm'
                  >
                    <div className='text-center mb-2'>
                      <MDBCardTitle className='text-left sub-title grey-text'>
                        <MDBRow>
                          <MDBCol md='12'>
                            <img src={require('../../assets/img/icons/laws.png')} alt='accounting icon' className='small-icon' />
                          </MDBCol>
                          <MDBCol md='12' className='mx-auto'>
                            <div className='tblue-text font-nanum-eb center h5-responsive'>Chartered Accountancy</div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardTitle>
                      <MDBCardBody className='pt-0  ml-2 mr-2 small-text text-justify grey-text font-nanum-eb'>
                        Business taxation and compliance, management accounting, business advice, trusts,
                        change management and accounting software support.
                      </MDBCardBody>
                    </div>
                  </MDBCol>
                  <MDBCol
                    sm='12'
                    md='6'
                    lg='6'
                    className='mx-auto no-padding-margin-sm'
                  >
                    <div className='text-left mb-2'>
                      <MDBCardTitle className='text-left sub-title grey-text'>
                        <MDBRow>
                          <MDBCol md='12'>
                            <img src={require('../../assets/img/icons/investment.png')} alt='investment icon' className='small-icon' />
                          </MDBCol>
                          <MDBCol md='12' className='mx-auto'>
                            <div className='tblue-text font-nanum-eb h5-responsive center'>Strategic Advisory</div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardTitle>
                      <MDBCardBody className='pt-0  ml-2 mr-2 small-text text-justify grey-text font-nanum-eb'>
                        Guiding and supporting businesses through challenges, enabling their business performance to improve.
                      </MDBCardBody>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          </div>
        </div> */}

        <MDBAnimation reveal type="fadeIn" className="slow">
          <div style={{ backgroundColor: "whitesmoke" }} className="pt-4 pb-4">
            <div className="padding-lg">
              <MDBContainer className="text-left pb-4">
                <div className="mt-lg-5 text-center">
                  <MDBRow className="mt-lg-3 mb-lg-5">
                    <MDBCol
                      md="7"
                      className="mx-auto grey-text text-left pr-lg-5"
                    >
                      <div className="">
                        <img
                          src={require("../../assets/img/Wayne_crop.jpeg")}
                          alt="Wayne Bailey"
                          className="img-fluid square-img mb-5 mx-auto"
                          style={{ maxHeight: "550px" }}
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="5" className="mx-auto text-center pb-4">
                      <div className="center">
                        <div className="display-h2 font-weight-md text-left mb-3 tblue-text font-nanum-eb">
                          Wayne Bailey
                        </div>
                        <h6 className="h5-responsive text-left font-space-xs mt-lg-5 mt-4 mb-1 font-nanum-eb">
                          Director &amp; Consultant
                          <br />
                          B.Com.
                          <br />
                          <br />
                          Wayne now maintains an exclusive portfolio of clients
                          as an experienced consultant. A self-confessed
                          'people-person', Wayne has an innate ability to foster
                          strong, enduring relationships with clients taking the
                          time to develop a genuine understanding of both their
                          current position, and their goals and aspirations,
                          before providing advice to maximise their preferred
                          outcomes.
                          <br />
                          <br />
                          Wayne is an expert in corporate restructuring,
                          financial proposals and succession planning and
                          retains several Board directorships. He brings his
                          positive attitude and outstanding experience to each
                          client engagement.
                          <br />
                          <br />
                          Wayne enjoys the company of family and friends, has
                          travelled extensively appreciating different cultures,
                          is a keen golfer, and an ardent supporter of music and
                          the arts.
                        </h6>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBContainer>
            </div>
          </div>
        </MDBAnimation>
        {/* 
        <div className='primary-bg pt-5 pb-5'>
          <MDBContainer>
            <div className='h1-responsive white-text text-left font-nanum mb-4 mt-3'>
              Creating a movement of <span className='font-weight-md'>brighter</span> financial futures.
            </div>
            <MDBAnimation reveal type='flipInX' className='slow pt-4 pb-4'>
              <MDBRow className='text-left'>
                <MDBCol md='4'>
                  <h6>We have clients across</h6>
                  <div className='display-h3 font-weight-md text-left mb-3 sky-text font-nanum-eb'>16 cities</div>
                </MDBCol>
                <MDBCol md='4'>
                  <h6>Our growing number</h6>
                  <div className='display-h3 font-weight-md text-left mb-3 sky-text font-nanum-eb'>2191 clients</div>
                </MDBCol>
                <MDBCol md='4'>
                  <h6>We have more than</h6>
                  <div className='display-h3 font-weight-md text-left mb-3 sky-text font-nanum-eb'>15 experts</div>
                </MDBCol>
              </MDBRow>
            </MDBAnimation>
          </MDBContainer>
        </div>

        <MDBAnimation reveal type='fadeIn' className='slow pt-4 pb-4'>
          <div className='padding-lg'>
            <MDBContainer className='h5-responsive pt-5 pb-5'>
              <div className='display-h2 font-weight-md text-left mb-3 tblue-text font-nanum-eb'>Who we are.</div>
              <MDBRow className='mx-auto mb-lg-5'>
                <MDBCol md='5' className='mx-auto'>
                  <div className='center text-center'>
                    <img
                      src={require('../../assets/img/icons/think.png')}
                      alt='investment icon'
                      className='img-fluid mt-lg-0 mt-3 mb-4'
                      style={{ maxHeight: '150px' }}
                    />
                  </div>
                </MDBCol>
                <MDBCol md='7' className='mx-auto text-left'>
                  <p className='grey-text mt-4 mb-4 font-nanum-eb'>
                    We are a team of experts with a range of divisions that work together to create a full service, end to end financial
                    solution that will help generate and protect your wealth for a growth based financial future.
                  </p>
                  <div style={{ lineHeight: '45px' }} className='font-nanum-eb'>
                    <MDBIcon icon='check' className='mr-3 sky-text' /> Consultant
                    <br />
                    <MDBIcon icon='check' className='mr-3 sky-text' /> Trust advisors
                    <br />
                    <MDBIcon icon='check' className='mr-3 sky-text' /> Strategic advisors
                    <br />
                    <MDBIcon icon='check' className='mr-3 sky-text' /> Property specialists
                    <br />
                    <MDBIcon icon='check' className='mr-3 sky-text' /> Financial Controllers
                    <br />
                  </div>
                  <div
                    onClick={() => this.props.history.push('/financial-solutions-company')}
                    className='mt-4 h6-responsive pointer text-small font-weight-md read-more font-nanum-eb'
                  >
                    Learn more <MDBIcon fa icon='arrow-right' className='ml-2' />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </MDBAnimation>

        <MDBAnimation reveal type='fadeIn' className='slow'>
          <div className='home-banner-2 page-header header-filter clear-filter blue-filter secondary-text'>
            <MDBMask overlay='black-strong' className='w-100 pt-5 pb-5'>
              <MDBCol md='10' lg='9' xl='8' className='mx-auto mt-5 mb-5'>
                <MDBContainer className='pt-5 pb-5 text-left'>
                  <h3 className='text-center white-text font-nanum-eb'>
                    We have a focus on supporting New Zealand <span className='font-weight-md sky-text'>families,</span> and we think
                    outside the box to make their dreams happen.
                  </h3>
                </MDBContainer>
              </MDBCol>
            </MDBMask>
          </div>
        </MDBAnimation> */}
        {/* 
        <MDBAnimation reveal type='fadeIn' className='slow'>
          <div className='padding-lg'>
            <MDBContainer className='pt-5 pb-5 secondary-text'>
              <h2 className='text-center secondary-text h1-responsive font-nanum-eb mt-5 mb-4'>
                What our bright futured clients have to say.
              </h2>
              <div className='text-center mb-5'>
                <MDBContainer className='pb-lg-5'>
                  <MDBCarousel
                    activeItem={1}
                    length={1}
                    showIndicators={true}
                    showControls={true}
                    autoPlay={true}
                    interval={15000}
                    className='no-flex'
                    style={{ minHeight: '400px' }}
                  >
                    <MDBCarouselInner>
                      <MDBCarouselItem itemId='1' className='text-center mt-5'>
                        <MDBCol md='7' className='mx-auto'>
                          {/* <div className='mx-auto mb-4 mt-5'>
                                                        <img src={require('../../assets/img/Steve-n-Vic.jpg')} alt="Steve and Vic" className="img-fluid square-img customer-img mb-2" style={{ filter: 'grayscale(100%)' }} />
                                                    </div> */}
        {/* <MDBRow>
                            <span style={{ lineHeight: '30px' }}>
                              <MDBIcon size='2x' icon='quote-left mr-2 mb-5 text-center' />
                              <h4 className='testimonal font-nanum black-text'>
                                Our goal has always been to bring life and new ideas to Christchurch, and Taurus has played a central role
                                in making this happen.
                              </h4>
                            </span>
                          </MDBRow>
                          <h4 className='font-weight-bold mt-2 mb-5 h6-responsive font-nanum-b'>Steve and Victoria Pomeroy</h4>
                          {/* <h6 className='font-weight-bold my-3 pb-2 h6-responsive primary-text'>Pomeroy's Old Brewery Inn</h6> */}
        {/* <img
                            src={require('../../assets/img/logos/pomeroys.svg')}
                            className='img-fluid mt-4'
                            alt="Pomeroy's Old Brewery Inn"
                            style={{ maxWidth: '350px' }}
                          />
                        </MDBCol> */}
        {/* </MDBCarouselItem> */}
        {/* </MDBCarouselInner>
                  </MDBCarousel>
                </MDBContainer>
              </div> */}
        {/* <MDBRow className='pt-5 mx-auto text-center'>
                <MDBCol md='4'>
                  <img
                    src={require('../../assets/img/logos/placeholder.png')}
                    className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                    alt="Pomeroy's Old Brewery Inn"
                    style={{ width: '300px' }}
                  />
                </MDBCol>
                <MDBCol md='4'>
                  <img
                    src={require('../../assets/img/logos/placeholder.png')}
                    className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                    alt="Pomeroy's Old Brewery Inn"
                    style={{ maxWidth: '300px' }}
                  />
                </MDBCol>
                <MDBCol md='4'>
                  <img
                    src={require('../../assets/img/logos/placeholder.png')}
                    className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                    alt="Pomeroy's Old Brewery Inn"
                    style={{ maxWidth: '300px' }}
                  />
                </MDBCol>
                <MDBCol md='6'>
                  <img
                    src={require('../../assets/img/logos/placeholder.png')}
                    className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                    alt="Pomeroy's Old Brewery Inn"
                    style={{ maxWidth: '300px' }}
                  />
                </MDBCol>
                <MDBCol md='6'>
                  <img
                    src={require('../../assets/img/logos/placeholder.png')}
                    className='img-fluid mt-5 mb-lg-5 mb-4 logo-grey'
                    alt="Pomeroy's Old Brewery Inn"
                    style={{ maxWidth: '300px' }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div> */}
        {/* </MDBAnimation> */}
      </div>
    );
  }
}

export default withRouter(Home);
