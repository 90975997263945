import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
const r = React;

export default class LogoSlider extends React.Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 600,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoPlaySpeed: 400,
      pauseOnHover: false,
      // swipe: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Slider {...settings}>
          {/* <div>
            <img
              className="logo-customer pt-0 mt-4 mb-4"
              alt="customer-logo"
              src={require("../../assets/img/logos/CAANZ_logo.jpg")}
            />
          </div> */}
          <div>
            <img
              className="logo-customer pt-0 mt-4 mb-4"
              alt="customer-logo"
              src={require("../../assets/img/logos/xero.jpg")}
            />
          </div>
          <div>
            <img
              className="logo-customer pt-0 mt-4 mb-4"
              alt="customer-logo"
              src={require("../../assets/img/logos/MYOB-logo.jpg")}
            />
          </div>
          <div>
            <img
              className="logo-customer pt-0 mt-4 mb-4"
              alt="customer-logo"
              src={require("../../assets/img/logos/spotlight.png")}
            />
          </div>
        </Slider>
      </div>
    );
  }
}
