import { MDBCol, MDBContainer, MDBFooter, MDBRow } from "mdbreact";
import React from "react";
import "./style.css";

const homeUrl = "/";
const contactUsUrl = "/contact-us";

class FooterPage extends React.Component {
  authCtx = null;

  navigate = (url) => {
    // navigate to top of page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  };

  toggleAuthModal = (show, page) => {
    this.authCtx.toggleShow(show, page);
  };

  logout = () => {
    this.authCtx.logout();
  };

  render() {
    this.authCtx = this.context.auth;

    return (
      <MDBFooter color="primary-color" className="font-small font-nanum pt-5">
        <MDBContainer className="pt-5 mb-4 text-center text-md-left footer-padding-top-bottom">
          <MDBRow className="mt-3">
            <MDBCol md="8" className="mb-4 m-lr-auto h5-responsive">
              <h3 className="heading-text font-weight-bold mb-4 font-nanum-eb">
                Contact us
              </h3>
              <p>
                <i className="fa fa-envelope mr-3" />{" "}
                <a className="white-text" href="mailto:ewb@taurusnz.co.nz">
                  ewb@taurusnz.co.nz
                </a>
              </p>
              <p>
                <i className="fa fa-phone mr-3" />{" "}
                <a className="white-text" href="tel:+6421329498">
                  +64 21 329 498
                </a>
              </p>
              {/* <p>
                <i className="fa fa-map-marker-alt mr-3" />270 St Asaph Street, Christchurch Central, Christchurch
            </p> */}
            </MDBCol>
            <MDBCol md="3" className="mb-4 m-lr-auto mx-auto">
              <h3 className="heading-text font-weight-bold mb-4 font-nanum-eb">
                Connect Socially
              </h3>
              <MDBCol
                md="6"
                lg="7"
                className="text-lg-left text-center text-md-right"
              >
                {/* <a className='fb-ic ml-0' href='https://www.facebook.com/taurusgroupnz' target='_blank' rel='noopener noreferrer'>
                  <i className='fab fa-facebook-f white-text mr-4 mr-lg-4' style={{ fontSize: '23px' }}>
                    {' '}
                  </i>
                </a> */}
                <a
                  className="li-ic ml-0"
                  href="https://www.linkedin.com/company/taurus-group-limited/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fab fa-linkedin white-text mr-4 mr-lg-4"
                    style={{ fontSize: "23px" }}
                  >
                    {" "}
                  </i>
                </a>
              </MDBCol>
            </MDBCol>
            <MDBCol md="8" className="mb-4 mt-5 m-lr-auto">
              <h3 className="heading-text font-weight-bold mb-4 font-nanum-eb">
                Taurus Group
              </h3>
              <div className="h5-responsive">
                Taurus now maintains an exclusive portfolio of clients by Wayne
                Bailey, experienced consultant. <br />
                <br />
              </div>
            </MDBCol>
            <MDBCol md="4" className="mb-4 mt-5 m-lr-auto text-center">
              <img
                src={require("../../assets/img/logos/navLogo-white.png")}
                alt="logo"
                className="mt-lg-5"
                style={{ height: "100px" }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright footer-padding font-text text-center">
          <MDBContainer fluid>
            <br />
            <div className="white-text text-uppercase">
              &copy; {new Date().getFullYear()} Copyright Taurus Group
            </div>
            <p className="text-uppercase mb-2 grey-text">
              <a
                href="http://xodesoftware.com/"
                rel="noopener noreferrer"
                alt="Designed by xodesoftware.com"
                target="_blank"
              >
                Web solution by Xode
              </a>
            </p>
          </MDBContainer>
        </div>
      </MDBFooter>
    );
  }
}
export default FooterPage;
