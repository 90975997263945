import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Nav from './Components/Nav/Nav'
import Home from './Components/Home/HomePage'
import Contact from './Components/Contact/ContactPage'
import Footer from './Components/Footer/Footer'

import '../src/assets/scss/style.scss'
import './App.css'

export function scrollTo(id) {
  var testDiv = document.getElementById(id)
  window.scrollTo({
    top: testDiv.offsetTop - 50,
    behavior: 'smooth'
  })
}

function App() {
  useEffect(() => {
    let ele = document.getElementById('init-loader')
    if (ele) {
      window.setTimeout(() => {
        ele.classList.add('loading-container-fade')
        window.setTimeout(() => {
          ele.classList.add('hide-loader')
        }, 550)
      }, 1050)
    }
  })

  return (
    <div className='App' style={{ overflow: 'hidden' }}>
      <Router>
        <Nav />
        <Switch>
          <Route path='/contact'>
            <Contact />
          </Route>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  )
}

export default App
