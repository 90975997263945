import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBBtn } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import './style.css'
import { axRequest, METHOD_POST } from '../../utils/request'
import { SK, SUCCESS_COLOR, FAILED_COLOR } from '../../utils/constants'

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      sendMessage: '',
      color: '',
      data: {
        key: SK,
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        extra: ''
      }
    }
  }

  submitHandler = async (event) => {
    event.preventDefault()
    event.target.className += ' was-validated'
    let inputs = event.nativeEvent.target
    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i]

      if (input.name === 'extra') continue

      if (input != null && !input.validity.valid) {
        return
      }
    }

    let s = this.state
    s.processing = true
    s.sendMessage = ''
    this.setState(s)

    let response = await axRequest(null, '/client-mailers', METHOD_POST, this.state.data)
    s = this.state
    if (response && response.success) {
      s.color = SUCCESS_COLOR
      s.sendMessage = 'Thank you for your message, we will be in touch soon.'
      this.resetForm()
    } else {
      s.color = FAILED_COLOR
      s.sendMessage = 'Oops, something went wrong, please try again!'
    }
    s.processing = false
    this.setState(s)
  }

  handleChange = (event) => {
    let s = this.state
    s.data[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    this.setState(s)
  }

  resetForm = () => {
    document.getElementById('contact-form').classList.remove('was-validated')
    let s = this.state
    s.data = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    }

    this.setState(s)
  }

  render() {
    return (
      <div className='mx-auto'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Contact | Taurus</title>
          {/* <description>We offer a full range of property, business and personal legal services to a diverse client base.</description> */}
          <link rel='canonical' href='https://www.taurusnz.co.nz/contact' />
        </Helmet>
        <ScrollToTop />
        <div className='contact-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter'>
          <div className='header-text'>
            <h2 className='h1-responsive text-left center font-weight-md mb-4 pl-lg-2 sky-text font-nanum-b'>Contact</h2>
            <h1 className='h1-responsive text-left center font-weight-md mb-4 pl-lg-2 font-nanum-b'>
              Get in touch with Wayne.
            </h1>
          </div>
        </div>
        <div id='about'>
          <div id='contact'>
            <MDBContainer className='mt-5 padding-lg'>
              <MDBRow className='secondary-text mb-5'>
                <MDBCol md='12' lg='6' className='text-left font-quatBold secondary-text mb-5'>
                  <h5 className='h3-responsive mb-4 font-nanum-eb'>Contact Details</h5>
                  <MDBRow>
                    <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                      <p>
                        <b className='font-quatBold'>Phone</b>
                      </p>
                    </MDBCol>
                    <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                      <p>
                        <a className='secondary-text' href='tel:+6421329498'>
                            +64 21 329 498{' '}
                        </a>
                      </p>
                    </MDBCol>
                    <MDBCol md='2' lg='2' className='mb-0 m-lr-auto'>
                      <p>
                        <b className='font-quatBold'>Email</b>
                      </p>
                    </MDBCol>
                    <MDBCol md='10' lg='10' className='mb-0 m-lr-auto'>
                      <p>
                        <a className='secondary-text' href='mailto:ewb@taurusnz.co.nz'>
                            ewb@taurusnz.co.nz
                        </a>
                      </p>
                    </MDBCol>
                    {/* <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                                            <p>
                                                <b className="font-quatBold">Postal</b>
                                            </p>
                                        </MDBCol>
                                        <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                                            <p>
                                                <a className="secondary-text" href="mailto:info@taurusnz.co.nz">PO Box 1129, Christchurch 8140</a>
                                            </p>
                                        </MDBCol> */}
                    <MDBCol md='12'>
                      <div className='text-left h3-responsive mx-auto mb-4 mt-4 font-nanum-eb'>
                        Send us a message to book an appointment
                      </div>
                      <form id='contact-form' className='needs-validation' onSubmit={this.submitHandler} noValidate>
                        {this.state.sendMessage && this.state.sendMessage.length > 0 ? (
                          <MDBRow>
                            <MDBCol style={{ minHeight: '45px', padding: '5px 0px 25px 0px', fontSize: '14px', textAlign: 'center' }}>
                              <MDBAnimation type='bounceIn' duration='500ms'>
                                <div
                                  className='font-quatRegular'
                                  style={{ padding: '15px 5px 0px 5px', fontWeight: 500, fontSize: '20px', color: this.state.color }}
                                >
                                  {this.state.sendMessage}
                                </div>
                              </MDBAnimation>
                            </MDBCol>
                          </MDBRow>
                        ) : null}
                        <div className='form-group'>
                          <label htmlFor='name' className='grey-text'>
                            Your name
                          </label>
                          <input
                            type='text'
                            id='name'
                            name='name'
                            className='form-control'
                            value={this.state.data.name}
                            onChange={this.handleChange}
                            disabled={this.state.processing}
                            required
                          />
                          <div className='invalid-feedback'>Required.</div>
                        </div>
                        <br />
                        <div className='form-group'>
                          <label htmlFor='email' className='grey-text'>
                            Your email
                          </label>
                          <input
                            type='email'
                            id='email'
                            name='email'
                            className='form-control'
                            value={this.state.data.email}
                            onChange={this.handleChange}
                            disabled={this.state.processing}
                            required
                          />
                          <div className='invalid-feedback'>Required.</div>
                        </div>
                        <br />
                        <div className='form-group'>
                          <label htmlFor='phone' className='grey-text'>
                            Your phone number
                          </label>
                          <input
                            type='tel'
                            id='phone'
                            name='phone'
                            pattern='^(0\d\d{7}|02\d\d{6,12}|0800\d{5,12})$'
                            className='form-control'
                            value={this.state.data.phone}
                            onChange={this.handleChange}
                            disabled={this.state.processing}
                            required
                          />
                          <div className='invalid-feedback'>Required.</div>
                        </div>
                        <br />
                        <div className='form-group'>
                          <label htmlFor='subject' className='grey-text'>
                            Subject
                          </label>
                          <input
                            type='text'
                            id='subject'
                            name='subject'
                            className='form-control'
                            value={this.state.data.subject}
                            onChange={this.handleChange}
                            disabled={this.state.processing}
                            required
                          />
                          <div className='invalid-feedback'>Required.</div>
                        </div>
                        <br />
                        <div className='form-group'>
                          <label htmlFor='message' className='grey-text'>
                            Your message
                          </label>
                          <textarea
                            type='text'
                            id='message'
                            name='message'
                            className='form-control'
                            rows='3'
                            value={this.state.data.message}
                            onChange={this.handleChange}
                            disabled={this.state.processing}
                            required
                          />
                          <div className='invalid-feedback'>Required.</div>
                        </div>
                        <div className='form-group' style={{ display: 'none' }}>
                          <label htmlFor='message' className='grey-text'>
                            Extra Info
                          </label>
                          <textarea
                            type='text'
                            id='extra'
                            name='extra'
                            className='form-control'
                            rows='3'
                            value={this.state.data.extra}
                            onChange={this.handleChange}
                            disabled={this.state.processing}
                            required
                          />
                          <div className='invalid-feedback'>Required.</div>
                        </div>
                        <div className='text-center mt-4'>
                          <MDBBtn
                            color='secondary'
                            type='submit'
                            style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                            disabled={this.state.processing}
                          >
                            Send message
                            {!this.state.processing ? null : (
                              <div className='spinner-border' style={{ width: '25px', height: '25px', marginLeft: '10px' }} role='status'>
                                <span className='sr-only'>Loading...</span>
                              </div>
                            )}
                          </MDBBtn>
                        </div>
                      </form>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                {/* <MDBCol md="12" lg="6" className="text-left mx-auto pl-lg-5 secondary-text mb-5">

                                    <MDBCol md="12">
                                        <div className="text-left h3-responsive mx-auto mb-4 font-nanum-eb">Our Office Locations</div>
                                    </MDBCol>
                                    <MDBCol md="12">
                                        <div className="text-left h4-responsive mx-auto mb-4 font-nanum-eb">Christchurch City</div>

                                        <MDBCol md="10" lg="10" className="mb-0 m-lr-auto mb-4 pl-0">
                                            <p>
                                                BOXed Quarter, 270 St Asaph Street Christchurch Central 8011
                                                </p>
                                        </MDBCol>
                                        <div className="map-container">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.359277022619!2d172.6402116157674!3d-43.536552190267685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a18c8db149d%3A0xa41132f46adea38!2sBoxed%20Quarter!5e0!3m2!1sen!2snz!4v1612349054269!5m2!1sen!2snz" width="100%" height="100%" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0" />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="12">
                                        <div className="text-left h4-responsive mx-auto mb-4 font-nanum-eb">Rolleston</div>
                                        <MDBCol md="10" lg="10" className="mb-0 m-lr-auto mb-4 pl-0">
                                            <p>
                                                68 Rolleston Drive, Rolleston 7614
                                                </p>
                                        </MDBCol>
                                        <div className="map-container">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.5647940528625!2d172.38389701576932!3d-43.59478059402283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d32034a81d99607%3A0x41ce049cccbce362!2s68%20Rolleston%20Drive%2C%20Rolleston%207614!5e0!3m2!1sen!2snz!4v1612349610804!5m2!1sen!2snz" width="100%" height="100%" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>                                            </div>
                                    </MDBCol>
                                    <MDBCol md="12">
                                        <div className="text-left h4-responsive mx-auto mb-4 font-nanum-eb">Auckland</div>
                                        <MDBCol md="10" lg="10" className="mb-0 m-lr-auto mb-4 pl-0">
                                            <p>
                                                BizDoJo Ponsonby, 4 Williamson Avenue, Grey Lynn, Auckland 1010
                                                </p>
                                        </MDBCol>
                                        <div className="map-container">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.3287885338477!2d174.74618521556053!3d-36.85854818776095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d47e851609357%3A0xa019c542cbdfeda5!2sBizDojo%20-%20Auckland%2C%20Cider%20Building!5e0!3m2!1sen!2snz!4v1612349651991!5m2!1sen!2snz" width="100%" height="100%" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>                                            </div>
                                    </MDBCol>
                                </MDBCol> */}
              </MDBRow>
            </MDBContainer>
            {/* <div>
                            <iframe className="map" title="Taurus Group Christchurch Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11650.796707927037!2d172.64185177435283!3d-43.531987475890816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d318a89fdff9897%3A0x4400ce078483bdea!2sTaurus%20Group%20Limited!5e0!3m2!1sen!2snz!4v1605437718242!5m2!1sen!2snz" width="100%" height="100%" frameBorder="0" style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>                </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ContactUsPage)
