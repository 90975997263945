export const API_BASE_URL = 'https://admin.xodesoftware.com';
export const SK = '^$WyE5m0@%6obTJMKf';

export const SUCCESS_COLOR = '#01427e';
export const FAILED_COLOR = '#d32f2f';

export const getQParamByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
