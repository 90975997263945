import React from 'react'
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBContainer,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavLink,
  MDBIcon
} from 'mdbreact'
import { withRouter, Link } from 'react-router-dom'
import './style.scss'

export const NAV_LINKS = [
//   { name: 'Home', path: '/' },
  { name: 'Contact', path: 'contact' }
]

class Nav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isTop: true,
      menuOpen: false,
      activeUrl: '/'
    }
  }
  toggleMenu = (e) => {
    if (e) e.preventDefault()
    let ele = document.getElementById('mdb-hamburger-menu')
    if (ele) {
      ele.classList.toggle('opened')
      ele.classList.toggle('closed')
    }

    let isOpen = !this.state.menuOpen
    this.setState({
      menuOpen: isOpen
    })
  }

  updateActiveUrl = (url) => {
    if (window.innerWidth < 992) {
      this.toggleMenu()
    }

    this.setState({
      activeUrl: url
    })

    if (url.includes('#')) {
      let urlSplit = url.split('#')
      if (urlSplit.length > 1) {
        let id = urlSplit[urlSplit.length - 1]
        this.scrollTo(id)
      }
    }
  }

  scrollTo(id) {
    let ele = document.getElementById(id)
    if (ele) {
      let top = ele.offsetTop
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  render() {
    const links = NAV_LINKS.map((l, i) => {
      return (
        <MDBNavItem key={i} className='pt-1' active={l.path === window.location.pathname} onClick={() => this.updateActiveUrl(l.path)}>
          <Link to={l.path} style={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <b className='white-text mr-lg-4'>{l.name}</b>
          </Link>
        </MDBNavItem>
      )
    })

    return (
      <>
        {/* <MobileNavOne links={NAV_LINKS} updateActiveUrl={this.updateActiveUrl} /> */}
        <MDBNavbar id='nav' color='primary-color' light expand='lg' fixed='top' scrolling transparent>
          <MDBContainer>
            <MDBNavbarBrand>
              <img
                src={require('../../assets/img/logos/navLogo-white.png')}
                alt='logo'
                className='nav-logo'
                style={{cursor: 'pointer'}}
                onClick={() => this.props.history.push('/')}
              />
            </MDBNavbarBrand>

            {/* <div className="mdb-hamburger-menu-container hide-mobile-nav">
                            <a id="mdb-hamburger-menu" class="closed" href="#menu" onClick={(e) => this.toggleMenu(e)}>
                                <div class="hamburger-line top"></div>
                                <div class="hamburger-line middle"></div>
                                <div class="hamburger-line bottom"></div>
                            </a>
                        </div> */}

            {/* <MDBCollapse id="navbarCollapse" isOpen={this.state.menuOpen} className={this.state.menuOpen ? 'fade-in' : null} navbar> */}
            <div style={{display: 'flex', listStyleType: 'none'}}>
              {links}
              {/* <MDBNavItem>
                <a className='fb-ic ml-0' href='https://www.facebook.com/taurusgroupnz' target='_blank' rel='noopener noreferrer'>
                  <MDBIcon fab icon='facebook-f' className='white-text mt-2 mr-2 mr-lg-4' />
                </a>
              </MDBNavItem> */}
              <MDBNavItem>
                <a
                  className='li-ic ml-0'
                  href='https://www.linkedin.com/company/taurus-group-limited/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <MDBIcon fab icon='linkedin' className='white-text mr-2 mt-2' />
                </a>
              </MDBNavItem>
            </div>
            {/* </MDBCollapse> */}
          </MDBContainer>
        </MDBNavbar>
      </>
    )
  }
}

export default withRouter(Nav)
